import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner'
import styles from './embed-video.scss'

class EmbedVideo extends Component {
  constructor(props) {
    super(props);
    const { hashedId, ...embedOptions } = { ...this.props }
    if (typeof window !== 'undefined') {
      window._wq = window._wq || [];
      window._wq.push({
        id: hashedId,
        options: embedOptions,
        onHasData: (video) => {
          this.handle = video;
        }
      });
    }
  }

  render() {
    return (
      <div className={`wistia-embed ${this.props.className ? this.props.className : ''}`} >
        <div className="wistia-embed-wrapper">
          <div className={`wistia_embed wistia_async_${this.props.hashedId} videoFoam=true text-center`}style={{height: '100%', width: '100%'}}>
            <Spinner animation="border" role="status" size='lg'><span className="sr-only">Video is loading...</span></Spinner>
            <br />
            <span>Video is loading...</span>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    if (!document.getElementById("wistia_script")) {
      const wistiaScript = document.createElement("script");
      wistiaScript.id = "wistia_script"
      wistiaScript.type = "text/javascript"
      wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
      wistiaScript.async = true
      document.body.appendChild(wistiaScript);
    }

  }

  componentWillUnmount() {
    this.handle && this.handle.remove();
  }
}

export default EmbedVideo
